<template>
  <div id="ouathLogin" @keydown.enter="handleSubmit">
    <a-row justify="center" align="top">
      <a-col span="6">
        <a-card :bordered="true" class="oauth-login-card">
          <a-form ref="loginRef" :model="loginForm" :rules="loginRules">
            <div class="oauth-login-img">
              <img src="~@/assets/images/login-logo-ott.png">
            </div>
            <a-form-item name="userName">
              <a-input v-model:value="loginForm.userName" placeholder="用户名" size="large" />
            </a-form-item>
            <a-form-item name="password">
              <a-input-password v-model:value="loginForm.password" type="password" placeholder="密码" size="large" />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" size="large" style="width: 100%" @click="handleSubmit">登录</a-button>
            </a-form-item>
            <p style="text-align: left">忘记密码？请联系{{ themeData.email }}</p>
          </a-form>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref, reactive, toRefs, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _login } from '@/api'
import { message } from 'ant-design-vue'
export default {
  name: 'OauthLogin',
  setup () {
    const loginRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      themeData: inject('$themeData'),
      merchantNo: '',
      redirectUrl: '',
      loginForm: {
        userName: null,
        password: null
      },
      loginRules: {
        userName: { required: true, message: '用户名不能为空' },
        password: { required: true, message: '密码不能为空' }
      }
    })

    const handleSubmit = async () => {
      loginRef.value.validateFields()
        .then(values => {
          return _login.oauthLogin(values)
        })
        .then(res => {
          if (res.data.succ) {
            const { data } = res.data
            router.push({
              path: '/oauth/oauthSubmit',
              query: {
                merchantNo: state.merchantNo,
                redirectUrl: state.redirectUrl,
                userName: state.loginForm.userName,
                token: data
              }
            })
          } else {
            message.error(res.data.resMsg)
          }
        })
    }

    const init = async () => {
      state.merchantNo = route.query.merchantNo
      state.redirectUrl = route.query.redirectUrl
      if (!state.merchantNo || !state.redirectUrl) {
        router.push('/error404')
        return
      }
      const res = await _login.checkIn({ merchantNo: state.merchantNo })
      if (!res.data.succ) {
        router.push('/error404')
      }
    }

    init()

    return {
      loginRef,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
  #ouathLogin {
    height: 100%;
    background-color: white;
    text-align: left;
    .oauth-login-card {
      height: 450px;
      margin-top: 120px;
      .oauth-login-img {
        margin-bottom: 36px;
        img {
          width: 180px;
        }
      }
    }
  }
</style>
